<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h6">
          BÀI VIẾT
        </span>
        <v-spacer />
        <v-btn
          color="blue"
          @click="Box = true"
        >
          Mới
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-list three-line v-if="items.length > 0">
            <template v-for="(item) in items">
              <div :key="item._id">
                <v-list-item
                >
                  <v-list-item-content>
                      <v-list-item-title>
                        <v-btn text block class="text-h6 white--text" router :to="`/thread/${item._id}`">{{item.Title}}</v-btn>
                      </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                      <v-list-item-subtitle>
                        <span class="text-body blue--text">Đăng bởi: {{item.Owner}}</span>
                        <br/>
                        <span class="text-body2 green--text"><v-icon color="green darken-2" small>mdi mdi-comment</v-icon> {{item.Replies}}</span>
                        <v-btn
                            class="mx-2"
                            icon
                            dark
                            x-small
                            color="red"
                            @click="doDelete(item._id)"
                            v-if="getUser.Access === 'admin'"
                        >
                          <v-icon dark>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-list-item-subtitle>
                  </v-list-item-action>
                </v-list-item>
                <v-divider />
              </div>
            </template>
          </v-list>
          <template v-else>
            <v-alert
              border="right"
              colored-border
              type="info"
            >
              <span
                class="blue--text text-center"
              >
                CHƯA CÓ BÀI VIẾT
              </span>
            </v-alert>
          </template>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="Box"
      max-width="900px"
    >
      <Header
        title="Bài viết mới"
        :close.sync="Box"
      >
        <v-form v-model="Valid">
        <v-card-title>
          <v-text-field
            v-model="threadData.Title"
            label="Tiêu đề"
            :rules="[required('Tiêu đề')]"
          />
        </v-card-title>
        <v-card-text>
          <quill-editor v-model="threadData.Content" :options="{theme: 'snow'}" />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="green"
            :disabled="!Valid"
            @click="doNewThread"
          >
            Đăng
          </v-btn>
        </v-card-actions>
        </v-form>
        <loading :status="loader" />
      </Header>
    </v-dialog>
    <loading :status="loading" />
  </div>
</template>

<script>
import validations from '@/helpers/validations.js'
import TopicService from '@/modules/baiviet.js'
import Header from '@/components/base/pagehead.vue'
import loading from '@/components/base/loading.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {ThongBao} from '@/helpers/thongbao.js';
import {mapGetters} from 'vuex'
export default {
  components: {
    Header,
    loading,
    quillEditor,
  },
  data () {
    return {
      threadData:{},
      loading:false,
      items:[],
      Box:false,
      Valid:false,
      loader:false,
    }
  },
  computed: {
    ...mapGetters(['isAuth','getUser']),
  },
  methods:{
    ...validations,
    doDelete(id){
      ThongBao.XacNhan('Bạn chắc chắn xóa ? Hành vi này không thể phục hồi !!!').then(confirm=>{
          if(confirm) {
            TopicService.DeleteThread({id}).then(resp=>{
              const {success} = resp;
              if(success){
                this.doLoad();
              }
            })
          }
      });
    },
    doNewThread(){
      this.loader = true;
      TopicService.NewThread(this.threadData).then(resp=>{
        const {success} = resp;
        if(success){
          this.doLoad();
          this.Box = false;
        }
      }).finally(()=>{
        this.loader = false;
      })
    },
    doLoad(){
      this.loading = true;
      TopicService.GetThread().then(resp=>{
        const {data} = resp;
        this.items = data;
      }).finally(()=>{
        this.loading = false;
      })
    }
  },
  mounted(){
    this.doLoad();
  }
}
</script>
